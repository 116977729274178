<template>
    <v-div
        elevation="1"
        class="secondary"
    >

    <v-avatar class="mx-auto d-flex" size="100">
    <v-img
        :src="getImage(item)"
    ></v-img>
    </v-avatar>

    <v-card-title class="headline d-flex justify-center">
        {{ item.firstname }} {{ item.lastname }}
    </v-card-title>

    <v-card-subtitle v-if="!item.position.includes('Student')" class="overline d-flex justify-center">
        {{ item.position }}
    </v-card-subtitle>
    
    <div class="d-flex justify-center">
        <v-btn icon :link='true' :href="getEmail(item.email)" target='_blank'>
        <v-icon class="pa-2" color="primary" title="email">fa-envelope
        </v-icon>
        </v-btn>
        <v-btn icon :link='true' :href="item.scholar" target='_blank' v-if="item.scholar!=undefined">
        <v-icon class="pa-2" color="primary" title="google scholar" >fa-graduation-cap
        </v-icon>
        </v-btn>
        <v-btn icon :link='true' :href="item.webpage" target='_blank' v-if="item.webpage!=undefined">
        <v-icon class="pa-2" color="primary" title="personal web page">fab fa-chrome
        </v-icon>
        </v-btn>
    </div>

    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">
</v-div>

</template>


<script>
import { Editor } from "vuetify-markdown-editor"
export default {
    name: 'Person',
    props: ['item'],
    components: {Editor},
    data: function () {
        return {
        show: false,
        }
    },
  methods: {
    getImage (call) {
      // console.log(call.image.formats.large.url)
      // var a = this.$api + call.image.formats.large.url
      var a = this.$api + call.image.url
      return a
    },
    getEmail (email) {
      return "mailto:" + email
    },
    getYear(date) {
            let d = new Date(date);
            return d.getFullYear();
        }
  },
}
</script>