<template>
  <v-card
    shaped  
    elevation="5"
    color="white"
    class="d-flex flex-column pa-6"
  >
    <v-card-text class="text-center"> 
        <h2>{{ item.title }} </h2>
    </v-card-text>
    <v-img v-if="item.image!=undefined"
      class="align-end"
      height="10em"
      contain
      :src="$api + item.image.url"
    >
    </v-img>
    <v-card-text class="text-justify">
        <Editor
          mode="viewer"
          ref="editor"
          hint="Hint"
          :outline="false"
          v-model="item.abstract"
        />
    </v-card-text>
    <v-row align="center">
      <v-card-actions class="col-8 ml-4" v-if="item.link">
        <v-btn
        color="primary"
        text
        :href="item.link"
        target="_blank"
        >
        <v-icon left>fa-link</v-icon>
          Project webpage
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="item.grantimage" class="col-3">
          <v-img :src="$api + item.grantimage.url">
          </v-img>
      </v-card-text>
      <!-- <v-card-text v-else-if="item.granttype" class="col-3 text-right">
          Grant: {{ item.granttype }}
      </v-card-text> -->
    </v-row>
  </v-card>
</template>

<script>
import { Editor } from "vuetify-markdown-editor"
export default {
    name: 'ProjectItem',
    props: ['item'],
    components: {Editor},
}
</script>