<template>
    <v-row>
      <v-img
        src="../assets/home_img.jpg"
      >
        <v-overlay>
            <div class="mb-15 pb-15" >
              <p class="text-h2 font-weight-bold white--text typing-title pb-15 mb-15" style="overlay: false;">
                {{ typedText }}
              </p>
            </div>
        </v-overlay>
          <!-- <v-col class="ma-0 pa-0" cols="8">
            <h4 class="text-justified white--text">
              {{ homepage.about }}
            </h4>
          </v-col> -->
      </v-img>
    </v-row>  
</template>

<style scoped>
  /* Add the fixed background styles to the container or body */
  body {
    margin: 0;
    background-image: url('../assets/home_img.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }

  /* Add overlay styles within the component's scoped styles */
  .overlay-container {
    background-color: rgba(255, 255, 255, 0.7); /* Adjust the overlay background color and opacity */
  }

    @keyframes typing {
    from {
      width: 0;
    }
  }

  .typing-title {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em; /* Adjust letter spacing for a more natural typing effect */
    animation: typing 3s steps(0, end), blink-caret 0.5s step-end infinite;
  }
</style>

<script>
// @ is an alias to /src
import { Editor } from "vuetify-markdown-editor"
import moment from "moment"
import backgroundUrl from '../assets/home_img.jpg'

export default {
  name: 'Home',
  components: {
    Editor
  },
  data: function () {
    return {
      backgroundUrl,
      maxwidth: "1000px",
      homepage: {frontpage: {url: ''}},
      news: [
        {
          image: {
            url: ''
          }
        }
      ],
      text: "",
      textContent: 'Multimedia and Human Understanding Group',
      typedText: '',
    }
  },
  mounted() {
    this.typeText();
  },
  methods: {
    typeText() {
      let index = 0;
      const interval = setInterval(() => {
        this.typedText += this.textContent[index];
        index++;
        if (index === this.textContent.length) {
          clearInterval(interval);
        }
      }, 100); // Adjust the typing speed (milliseconds per character)
    },
    getFrontpage (call) {
      // console.log(call.image.formats.large.url)
      var a = this.$api + call.frontpage.url
      // console.log(a)
      return a
    },
    iconSelector (icn) {
      if (icn == 'publication') {
        return 'mdi-book';
      } else if (icn == 'news') {
        return 'mdi-newspaper'
      }
    },
    formatDate (date) {
      return moment(new Date(date)).format("MMMM Do, YYYY")
    }
  },
  beforeMount () {
    this.$axios.get(this.$api + '/homepage').then(function (res) {
      this.homepage = res.data
    }.bind(this))
    this.$axios.get(this.$api + '/updates').then(function (res) {
      this.news = res.data
      this.news.sort((a, b) => { 
        // a.show = false
        // b.show = false
        if (a.created_at < b.created_at) {
          return -1
        } else {
          return 1
        }
      })
    }.bind(this))
    // this.$axios.get(this.$api + '/projects').then(function (res) {
    //   this.projects = res.data
    // }.bind(this))
  }
}
</script>
