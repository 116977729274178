import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import colors from 'vuetify/lib/util/colors'
import mhugicon from '@/components/mhug_icon.vue'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#30343F',
            secondary: '#FAFAFF',
            tertiary: '#E4D9FF',
            tertiaryDark: '#273469',
            tertiaryDarker: '#1e2749',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
    icons: {
        iconfont: 'fa',
        values: {
            mhug: {
                component: mhugicon,
            }
        }
    }
});
