<template>
  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <v-container fluid>
    <div class="people">
      <div class="d-flex justify-center mt-8 mb-10">
        <h1>Principal Investigators</h1>
      </div>
      
      <div class="row d-flex">
        <v-card v-for="item in professors" 
          v-bind:key="item.id"
          shaped
          class="mx-auto col-5 text-justify"
          elevation="1"
          >
          <v-list-item-title class="headline mb-1 d-flex justify-center">
            {{item.firstname}} {{item.lastname}}
          </v-list-item-title>
          <div class="overline mb-1 d-flex justify-center">
            {{item.position}}
          </div>
            <v-row>
              <v-col>
                <v-avatar class="mx-auto d-flex" size="150">
                <v-img
                    :src="getImage(item)"
                ></v-img>
              </v-avatar>
                <!-- <div class="d-flex justify-center">
                  <v-btn icon :link='true' :href="getEmail(item.email)" target='_blank'>
                  <v-icon color="primary" title="email">fa-envelope
                  </v-icon>
                  </v-btn>
                  <v-btn icon :link='true' :href="item.scholar" target='_blank' v-if="item.scholar!=undefined">
                  <v-icon color="primary" title="google scholar" >fa-graduation-cap
                  </v-icon>
                  </v-btn>
                  <v-btn icon :link='true' :href="item.webpage" target='_blank' v-if="item.webpage!=undefined">
                  <v-icon color="primary" title="personal web page">fab fa-chrome
                  </v-icon>
                  </v-btn>
                </div> -->
              </v-col>
            </v-row>
            <div>
              <v-expansion-panels class="mt-3" flat>
                <v-expansion-panel>
                  <v-expansion-panel-header expand-icon="">
                    <div class="d-flex justify-center text-decoration-underline">
                      Bio & Contacts
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <Editor
                      mode="viewer"
                      ref="editor"
                      hint="Hint"
                      :outline="false"
                      class="text-justify"
                      v-model="item.bio"
                    />
                    <div class="d-flex justify-center">
                      <v-btn icon :link='true' :href="getEmail(item.email)" target='_blank'>
                      <v-icon color="primary" title="email">fa-envelope
                      </v-icon>
                      </v-btn>
                      <v-btn icon :link='true' :href="item.scholar" target='_blank' v-if="item.scholar!=undefined">
                      <v-icon color="primary" title="google scholar" >fa-graduation-cap
                      </v-icon>
                      </v-btn>
                      <v-btn icon :link='true' :href="item.webpage" target='_blank' v-if="item.webpage!=undefined">
                      <v-icon color="primary" title="personal web page">fab fa-chrome
                      </v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
        </v-card>
      </div>
      
      <v-sheet class="row d-flex justify-center mt-12 py-3" outlined>
      <v-col cols="12">
        <div class="d-flex justify-center"> 
        <h2>Senior Researchers</h2>
      </div>
      </v-col>
      <v-col v-for="item in researchers" v-bind:key="item.id" class="mx-auto" cols="3" elevation="0">
        <Person :item="item" />
      </v-col>
    </v-sheet>
      
      <v-sheet class="row d-flex justify-center mt-12 py-3" outlined> 
        <v-col cols="12"> 
          <div class="d-flex justify-center">
          <h2>Ph.D. Students</h2>
        </div>
        </v-col>
        <v-col v-for="item in students" v-bind:key="item.id" cols="3" class="mx-auto">
            <div><Student :item="item"/></div> 
        </v-col>
      </v-sheet>

      <v-sheet class="row d-flex justify-center mt-12 py-3" outlined>
        <v-col cols="12">
          <div class="d-flex justify-center"> 
            <h2>Visiting Students @MHUG</h2>
          </div>
        </v-col>
        <v-col v-for="item in visiting" v-bind:key="item.id" cols="3" class="mx-auto">
          <Student :item="item" />
        </v-col>
    </v-sheet>


    <v-sheet class="row d-flex justify-center mt-12 py-3" outlined>
      <v-col cols="12">
        <div class="d-flex justify-center"> 
          <h2>Collaborators</h2>
        </div>
      </v-col>
      <v-col v-for="item in others" v-bind:key="item.id" cols="3" class="mx-auto">
        <Collaborator :item="item"/>
      </v-col>
    </v-sheet>
    
    <v-sheet class="row d-flex justify-center mt-12 py-3 secondary" outlined>
      <!-- <v-expansion-panels flat>
        <v-expansion-panel class="secondary">
    
          <v-expansion-panel-header expand-icon=""> -->
            <!-- <v-col cols="12">
              <v-divider></v-divider>
            </v-col> -->
            <v-col cols="12">
              <div class="d-flex justify-center"> 
                <h2>Former Members</h2>
              </div>
            </v-col>
          <!-- </v-expansion-panel-header>
    
          <v-expansion-panel-content> -->
          <div class="row d-flex align-start"> 
            <template v-for="(item, index) in former">
              <div class="col-lg-3 col-md-6 col-12">
                <v-list-item>
                  <a :href="item.webpage" target="_blank">
                    <v-list-item-avatar size='80'>
                      <v-img :src="getImage(item)"></v-img>
                    </v-list-item-avatar>
                  </a>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.firstname + ' '+ item.lastname"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.position" class="text-wrap"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </div>
        <!-- </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
  </v-sheet>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { Editor } from "vuetify-markdown-editor"
import Person from '@/components/person.vue'
import Student from '@/components/student.vue'
import Collaborator from '@/components/collaborator.vue'

export default {
  name: 'People',
  components: {
    Editor, Person, Student, Collaborator
  },
  data: function () {
    return {
      professors: [],
      researchers: [],
      students: [],
      visiting: [],
      others: [],
      former: [],
    }
  },
  methods: {
    getImage (call) {
      // console.log(call.image.formats.large.url)
      // var a = this.$api + call.image.formats.large.url
      var a = this.$api + call.image.url
      return a
    },
    getEmail (email) {
      return "mailto:" + email
    }

  },
  beforeMount () {
    this.$axios.get(this.$api + '/people?_limit=-1').then(function (res) {
      var people = res.data
      people.sort((a, b) => { 
        // a.show = false
        // b.show = false
        if (a.lastname < b.lastname) {
          return -1
        } else {
          return 1
        }
        return 0
        }
      )
      var i;
      for (i = 0; i < people.length; i++) {
        if (people[i].active == true) {
          if (people[i].position == 'Full Professor' || people[i].position == 'Associate Professor'){
            this.professors.push(people[i])
          } else if (people[i].position == 'Assistant Professor (RTDa)' || people[i].position == 'Assistant Professor (RTDb)' || people[i].position == 'Post-Doc'){
            this.researchers.push(people[i])
          } else if (people[i].position.includes('Student')) {
            if (people[i].visiting == true) {
              this.visiting.push(people[i])
            } else {
              this.students.push(people[i])
            }
          } else {
            this.others.push(people[i])
          }
        } else {
          this.former.push(people[i])
        }
      }

      // reverse sort professorslco
      this.professors = this.professors.reverse()

      // sort phd students by year
      this.students.sort((a, b) => { 
        if (a.starting_year < b.starting_year) {
          return -1
        } else {
          return 1
        }
      })
      
    }.bind(this));
  },
  mounted () {

  }
}
</script>

<style lang="scss">

.nounderline {
  text-decoration: none;
}

// .v-list-item--avatar{
// justify-content: center;
// text-align: left;
// display: grid;
// }

div.card{
  width: 1000;
}

</style>