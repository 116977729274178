import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@babel/polyfill'
// import VueAnalytics from 'vue-analytics';

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$api = process.env.NODE_ENV !=  'production' ? "http://localhost:1337" : '/api'
// Vue.prototype.$api = "http://mhug.disi.unitn.it:1336"
// Vue.prototype.$api = "http://localhost:1337"
Vue.prototype.$window = window

// // Configuration VueAnalytics
// Vue.use(VueAnalytics, {
//   id: 'UA-270024525',
//   router
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
},
).$mount('#app')
