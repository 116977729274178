<template>
    <v-div>
    <v-avatar class="mx-auto d-flex" size="100">
    <v-img
        :src="getImage(item)"
    ></v-img>
    </v-avatar>

    <v-card-title class="d-flex justify-center mb-0 pb-0 text-subtitle-1 font-weight-medium">
        {{ item.firstname }} {{ item.lastname }}
    </v-card-title>

    <!-- STUDENTS MUST NOT INCLUDE THEIR POSITION, SINCE THEY ARE ALL GROUPED UNDER 'PHD STUDENTS' -->
    <!-- <v-card-subtitle v-if="!item.position.includes('Student')" class="overline d-flex justify-center">
        {{ item.position }}
    </v-card-subtitle> -->

    <!-- PLACEHOLDER FOR TOPIC SUBTITITLE IN STUDENT CARD -->
    <v-card-text v-if="item.visiting != true" class='d-flex justify-center my-0 py-0 text-caption text--secondary'>
        <!-- THIS ICON WAS USELESS -->
        <!-- <v-icon color="primary" size="8">fas fa-hand-point-right</v-icon> -->
        {{item.topic}}
    </v-card-text>

    <v-card-text v-if="item.visiting == true" class='d-flex justify-center my-0 py-0 text-caption text--secondary'>
        <!-- THIS ICON WAS USELESS -->
        <!-- <v-icon color="primary" size="8">fas fa-hand-point-right</v-icon> -->
        {{item.from}}
    </v-card-text>
    
    <!-- CONTACTS -->
    <div class="d-flex justify-center mt-0 pt-0">
        <v-btn icon :link='true' :href="getEmail(item.email)" target='_blank'>
        <v-icon color="primary" size="16" title="email" class="mt-0 pt-0">fa-envelope</v-icon>
        </v-btn>
        <v-btn icon :link='true' :href="item.scholar" target='_blank' v-if="item.scholar!=undefined">
        <v-icon size="16" color="primary" title="google scholar" class="mt-0 pt-0">fa-graduation-cap</v-icon>
        </v-btn>
        <v-btn icon :link='true' :href="item.webpage" target='_blank' v-if="item.webpage!=undefined">
        <v-icon size="16" color="primary" title="personal web page" class="mt-0 pt-0">fab fa-chrome</v-icon>
        </v-btn>
    </div>

    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css" rel="stylesheet">
</v-div>

</template>


<script>
import { Editor } from "vuetify-markdown-editor"
export default {
    name: 'Student',
    props: ['item'],
    components: {Editor},
    data: function () {
        return {
        show: false,
        }
    },
  methods: {
    getImage (call) {
      // console.log(call.image.formats.large.url)
      // var a = this.$api + call.image.formats.large.url
      var a = this.$api + call.image.url
      return a
    },
    getEmail (email) {
      return "mailto:" + email
    },
    getYear(date) {
            let d = new Date(date);
            return d.getFullYear();
        }
  },
}
</script>