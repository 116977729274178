<template>
    <v-card
        shaped  
        elevation="1"
        color="secondary"
        class="d-flex flex-column pa-2"
    >
      <v-card-text class="text-center"> 
        <v-btn
              text
              :href="item.link"
              v-if="item.link"
          >
          <h2>{{ item.title }}</h2>
          <v-icon right>fa-link</v-icon>
        </v-btn>
        <v-btn text style="pointer-events: none" v-else>
          <h2>{{ item.title }}</h2>
        </v-btn>
      </v-card-text>
      <v-avatar class="mx-auto d-flex" size="10em">
          <v-img v-if="item.image!=undefined"
            class="mb-6"
            height="10em"
            contain
            :src="$api + item.image.url"
          >
          </v-img>
      </v-avatar>
    </v-card>
  </template>
  
  <script>
  import { Editor } from "vuetify-markdown-editor"
  export default {
      name: 'ProjectItem',
      props: ['item'],
      components: {Editor},
  }
  </script>