<template>
    <v-container fluid>
    <div class="d-flex justify-center align-center">
        <h1>
          Ongoing Projects
        </h1>
    </div>
    <br>
    <div v-for="index in Math.ceil(active_projects.length / 2)" :key="index" class="row justify-center">
      <div v-if="active_projects[(index - 1) * 2]" class="col-5 ma-2 d-flex">
        <ProjectItem :item="active_projects[(index - 1) * 2]" />
      </div>
      <div v-if="active_projects[(index - 1) * 2 + 1]" class="col-5 ma-2 d-flex">
        <ProjectItem :item="active_projects[(index - 1) * 2 + 1]" />
      </div>
    </div>

    <br>
    <br>
    <v-sheet class="row justify-center secondary">
      <v-col cols="12">
        <div class="d-flex justify-center"> 
          <h1>Past Projects</h1>
        </div>
      </v-col>
      <div v-for="index in Math.ceil(inactive_projects.length / 2)" :key="index" class="row justify-center">
        <div v-if="inactive_projects[(index - 1) * 2]" class="col-3 ma-2">
          <PastProject :item="inactive_projects[(index - 1) * 2]" />
        </div>
        <div v-if="inactive_projects[(index - 1) * 2 + 1]" class="col-3 ma-2">
          <PastProject :item="inactive_projects[(index - 1) * 2 + 1]" />
        </div>
      </div>
    </v-sheet>
    </v-container>
</template>

<script>
// @ is an alias to /src
import { Editor } from "vuetify-markdown-editor"
import ProjectItem from "@/components/projectitem.vue"
import PastProject from "@/components/past_project.vue"

export default {
  name: 'Projects',
  components: {
    Editor, ProjectItem, PastProject
  },
  data: function () {
    return {
        active_projects: [],
        inactive_projects: []
    }
  },
  methods: {
    
  },
  beforeMount () {
    this.$axios.get(this.$api + '/projects').then(function (res) {
      this.projects = res.data
      var projects = res.data
      projects.sort((a, b) => { 
          var a_end_date = new Date(a.enddate)
          var b_end_date = new Date(b.enddate)
          if (a_end_date > b_end_date) {
            return -1
          } else {
            return 1
          }
        }
      )
      var i;
      var today = new Date()
      for (i = 0; i < projects.length; i++) {
        var project_end_date = new Date(projects[i].enddate)
        if (project_end_date.valueOf() >= today.valueOf()){
          this.active_projects.push(projects[i])
        }else{
          this.inactive_projects.push(projects[i])
        }
      }
    }.bind(this))
  }
}
</script>

<style lang="scss">

</style>