<template>
    <v-card
        v-if='item != undefined'
        shaped  
        elevation="5"
        class="pa-4 d-flex flex-vertically"
        height="100%"
        width="100%"
    >
    <v-row>
        <v-list-item class="col">
            <v-list-item-content class="text-center">
                <p class="headline font-weight-bold text-h6">
                {{ item.title }}
                </p>
                <v-list-item-content>
                    <p class="mb-2 text-subtitle-2" style="color: #8a8a8a;">
                        {{ item.authors }}
                    </p>
                    <p v-if='item.award_tag' class="font-weight-bold" >{{ item.award_tag }}</p>
                    <v-img v-if="item.image" :src="getImage(item)" contain height="10em" width="10em" class="mt-4"/>
                    <div class="mt-4">
                        <v-chip v-if="item.pdflink" :href="item.pdflink" target="_blank"> 
                            <v-icon size="16" color="primary" title="pdf">fa-file-pdf</v-icon>
                        </v-chip>
                        <v-chip class="ml-2" v-if="item.code"  :href="item.code" target="_blank"> 
                            <!-- add github icon -->
                            <v-icon size="16" color="primary" title="code">fa-brands fa-github</v-icon>
                        </v-chip>
                    </div>
                </v-list-item-content>
            </v-list-item-content>                
        </v-list-item>
    </v-row>
    </v-card>

</template>


<script>
import { Editor } from "vuetify-markdown-editor"
export default {
    name: 'pubitem',
    props: ['item'],
    components: {Editor},
    methods: {
        getYear(date) {
            let d = new Date(date);
            return d.getFullYear();
        },
        getImage (call) {
            // console.log(call.image.formats.large.url)
            // var a = this.$api + call.image.formats.large.url
            var a = this.$api + call.image.url
            return a
        }
  },
}
</script>