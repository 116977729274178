<template>
  <v-container fluid class="text-center">
    <div class="d-flex justify-center text-center">
      <v-row class="d-flex justify-center text-center mt-5">
        <p class="text-h4 font-weight-bold">
            Selected publications
        </p>
      </v-row>
    </div>
    <!-- Iterate through venues -->

    <v-row class="my-6 justify-center">
      <v-btn :color="selectedFilter === 'all' ? 'primary' : '#ECEFF1'" class="mr-4" @click="filterItems('all')">All</v-btn>
      <v-btn :color="selectedFilter === 'conference' ? 'primary' : '#ECEFF1'" class="mr-4" @click="filterItems('conference')">Conference</v-btn>
      <v-btn :color="selectedFilter === 'journal' ? 'primary' : '#ECEFF1'" @click="filterItems('journal')">Journal</v-btn>
    </v-row>

    <v-card fluid fill-height color="secondary" v-for="items in paginatedGroupedPubs" :key="groupKey" class="mb-6" elevation="0">
      <v-card-title class="justify-center text-h5 font-weight-bold pb-1">{{ items[0].venue }} - {{ getYearFromPubdate(items[0].pubdate) }}</v-card-title>

      <!-- Iterate through publications in the current venue and year -->
      <v-row class="d-flex justify-center text-center ma-0 pa-0">
        <v-col v-for="item in items" :key="item.title" cols="12" sm="2" md="4" lg="5" xl="5" class="d-flex ma-3 justify-center">
          <PubItem :item="item" />
        </v-col>
      </v-row>
    </v-card>
    <v-row style="position: sticky; bottom: 15px; left: 0; right: 0; justify-content: center;">
      <v-pagination class="pa-2" style="position: sticky; bottom: 15px; left: 50%; transform: translateX(-50%); background-color: #ECEFF1; border-radius: 40px; z-index: 1000;" :total-visible="6" v-model="currentPage" :length="totalPages" @input="handlePageChange"></v-pagination>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import PubItem from '@/components/pubitem.vue'
import moment from "moment"

export default {
  name: 'Projects',
  data: function () {
    return {
      pubs: [
      ],
      filteredPubs: [],
      currentPage: 1,
      itemsPerPage: 3,
      filterType: 'all',
      selectedFilter: 'all'
    }
  },
  methods: {
    getYear(date) {
        let d = new Date(date);
        return d.getFullYear();
    },
    getYearFromPubdate(pubdate) {
      const year = new Date(pubdate).getFullYear();
      return year;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    filterItems(type) {
      // Reset currentPage to 1 when filtering
      this.currentPage = 1;

      // Filter items based on the selected type
      if (type === 'all') {
        this.filteredPubs = [...this.pubs];  // Copy all pubs to filteredPubs
      } else if (type === 'conference') {
        this.filteredPubs = this.pubs.filter(item => !item.isjournal);
      } else if (type === 'journal') {
        this.filteredPubs = this.pubs.filter(item => item.isjournal);
      }

      this.filterType = type;  // Update the currently selected filter type
      this.selectedFilter = type;
    }
  },
  computed: {
    // Computed property to group publications by venue and year
    groupedPubs() {
      const grouped = {};
      this.filteredPubs.forEach((item) => {
        if (item.selected) {
          const venue = item.venue || 'Other'; // Use 'Other' if venue is undefined
          const year = this.getYear(item.pubdate);
          const groupKey = `${venue} ${year}`;
          if (!grouped[groupKey]) {
            grouped[groupKey] = [];
          }
          
          grouped[groupKey].push(item);
        }
      });

      // Sort each group based on title and award_tag
      for (const groupKey in grouped) {
        if (grouped.hasOwnProperty(groupKey)) {
          grouped[groupKey].sort((a, b) => {
            // First, order by award_tag (non-empty first)
            if (b.award_tag && !a.award_tag) {
              return 1; // b comes first if b has an award_tag and a does not
            } else if (!b.award_tag && a.award_tag) {
              return -1; // a comes first if a has an award_tag and b does not
            }

            // If award_tag is the same or both are empty, order by title
            return a.title.localeCompare(b.title);
          });
        }
      }
      return grouped;
    },
    paginatedGroupedPubs() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      const paginatedGroups = Object.entries(this.groupedPubs).slice(startIndex, endIndex);
      return Object.fromEntries(paginatedGroups);
    },
    totalPages() {
      return Math.ceil(Object.keys(this.groupedPubs).length / this.itemsPerPage);
    },
  },
  components: {
      PubItem
  },

  beforeMount () {
    this.$axios.get(this.$api + '/publications?_limit=-1').then(function (res) {
      this.pubs = res.data
      let validPubs = []
      this.pubs.forEach(element => {
        let d = new Date(element.pubdate)
        const start = Date.now()
        if (moment(d) > moment(start).subtract(2, 'years')) {
          validPubs.push(element)
        }
      });
      this.pubs = validPubs
      this.pubs.sort((a, b) => { 
        // a.show = false
        // b.show = false
        if (a.pubdate > b.pubdate) {
          return -1
        } else {
          return 1
        }
      })
      this.filteredPubs = [...this.pubs];
    }.bind(this))
  }
}
</script>

<style lang="scss">

</style>