<template>
    <v-container fluid>
    <div class="research">
      <v-row class="d-flex ma-5">
         <h1>
            Research Topics
        </h1>
      </v-row>
       
        <div v-for="item in topics" v-bind:key="item.title" class="d-flex ma-3 justify-center">
          <v-card
            shaped  
            elevation="5"
            max-width="1000"
            color="secondary"
          > 
          <v-card-title>{{item.title}} </v-card-title>
          <v-row>
            <v-col cols="3">
               <v-img max-width="300" v-if="item.image!=null" :src="$api + item.image.url" class="ma-3">
                </v-img>
            </v-col>
            <v-col cols="9">
               <v-list-item-content class="mb-1 pa-0">
                  <Editor
                      mode="viewer"
                      ref="editor"
                      hint="Hint"
                      :outline="false"
                      v-model="item.body"
                    />
               </v-list-item-content>
            </v-col>
          </v-row>
          </v-card>
        </div>
    </div>
    </v-container>
</template>

<script>
// @ is an alias to /src
import { Editor } from "vuetify-markdown-editor"

export default {
  name: 'Research',
  components: {
    Editor
  },
  data: function () {
    return {
      topics: []
    }
  },
  methods: {
    
  },
  beforeMount () {
    this.$axios.get(this.$api + '/topics').then(function (res) {
      this.topics = res.data
      console.log(this.topics)
      
    }.bind(this));
  }
}
</script>

<style lang="scss">

</style>