<template>
    <v-container fluid>
    <div class="datasets">
      <v-row class="d-flex ma-5">
         <h1>
            Datasets
        </h1>
      </v-row>
    </div>
    <div v-for="item in datasets" v-bind:key="item.title" class="col-12 ma-2">
      <v-card 
      shaped 
      color="secondary" 
      max-width="1000">
        <v-card-title>
          {{item.title}}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols=4>
              <v-img v-if="item.image!=undefined"
                class="white--text align-end"
                height="200px"
                :src="$api + item.image.url"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="8">
              <Editor
                mode="viewer"
                ref="editor"
                hint="Hint"
                :outline="false"
                v-model="item.description"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-chip class="ml-5" v-if="item.link!=undefined && item.link!=''" :href="item.link" target="_blank"> 
                Link
            </v-chip>
            <v-chip class="ml-5" v-if="item.webpage !== undefined && item.webpage!=''" :href="item.webpage" target="_blank"> 
                Webpage
            </v-chip>
            <v-chip class="ml-5" v-if="item.paper !== undefined && item.paper!=''" :href="item.paper" target="_blank"> 
                Reference Paper
            </v-chip>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    </v-container>
</template>

<script>
// @ is an alias to /src
import { Editor } from "vuetify-markdown-editor"

export default {
  name: 'Datasets',
  components: {
    Editor
  },
  data: function () {
    return {
        datasets: [
        ]
    }
  },
  methods: {
    
  },
  beforeMount () {
    this.$axios.get(this.$api + '/datasets').then(function (res) {
      this.datasets = res.data
      console.log(this.datasets)
    }.bind(this))
  }
}
</script>

<style lang="scss">

</style>