import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import People from '../views/People.vue'
import Projects from '../views/Projects.vue'
import Publications from '../views/Publications.vue'
import Research from '../views/Research.vue'
import Datasets from '../views/Datasets.vue'
// import News from '../views/News.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/papers',
    name: 'Papers',
    component: Home
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/datasets',
    name: 'Datasets',
    component: Datasets
  },
  {
    path: '/pubs',
    name: 'Publications',
    component: Publications
  },
  {
    path: '/research',
    name: 'Research',
    component: Research
  },
  {
    path: '/people',
    name: 'People',
    component: People
  },
  {
    path: '/contacts',
    name: 'Contacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contacts.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
