<template>
  <v-app id="inspire">
    
    <div class="app-container">
      <v-app-bar color="primary" app>
        <v-row>
          <v-col cols="2">
            <v-toolbar-title class='ml-3 white--text' style="cursor: pointer;" v-on:click="$router.push('/')">
              <v-avatar v-on:click="$router.push('/')" style="background-color: whitesmoke;" size="36" class="mr-2">
                <v-icon size="22">
                  $vuetify.icons.mhug
                </v-icon>
              </v-avatar>
              MHUG
            </v-toolbar-title>
        
        </v-col>
        <v-col cols="8" class="d-flex justify-center">
        <v-btn-group>
          <v-btn text
            v-for="item in items"
            :key="item.title"
            v-on:click="$router.push( {name: item.title} )"
            class="white--text"
          >
            {{ item.title }}
          </v-btn>
      </v-btn-group>
      </v-col>
      <v-col cols="2"></v-col>
      </v-row>
      </v-app-bar>
    </div>

    <!-- <v-navigation-drawer
      v-model="drawer"
      color="secondary"
      app
    >

      
    </v-navigation-drawer> -->


    <!-- <v-app-bar app flat color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3">Multimedia and Human Understanding Group</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon large class="mr-3">$vuetify.icons.mhug</v-icon>
    </v-app-bar> -->

    <v-main class="secondary">
      <v-container class="d-flex flex-column fill-height" fluid>
        <router-view></router-view>
        <!--  -->
      </v-container>
    </v-main>
    <v-footer
      color="white"
      class="text-center d-flex flex-column pt-4"
    >
      <h3>
        Contacts
      </h3>

      <div class="pt-0">
        Nicu Sebe · <a href="mailto:niculae.sebe@unitn.it">niculae.sebe@unitn.it</a><br>
        Elisa Ricci · <a href="mailto:e.ricci@unitn.it">e.ricci@unitn.it</a> · <a href="https://twitter.com/eliricci_">@eliricci_</a><br>
        Department of Information Engineering and Computer Science - DISI <br>
        Via Sommarive , 9 I-38123 Povo (TN)
      </div>

      <v-divider></v-divider>
    </v-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
    name: 'App',
    // components: {
    //   HelloWorld,
    // },
    data: () => ({
        drawer: null,
        items: [
            { title: 'Home' },
            { title: 'People'},
            { title: 'Publications'},
            { title: 'Projects'}
        ]
    }),
    methods: {
    // debug (value) {
    //   console.log(value)
    //   return value
    // }
    },
    components: { }
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round');
#inspire {
  font-family: 'Rubik', ital;
};

</style>
